/* eslint-disable no-underscore-dangle */
import fetch from 'node-fetch';
import store from '@/store';

async function makeApiRequest (path, method = 'GET', body = {}, signal = null) {
  const params = {
    method: method,
    headers: {
      'content-Type': 'application/json',
      Authorization: `Bearer ${store.state.token}`
    },
    body: Object.keys(body).length !== 0 ? JSON.stringify(body) : undefined,
    signal: signal
  };
  return fetch(`${path}`, params)
    .then(response => response.json())
    .then(async response => {
      if (response.redirectUrl) {
        // store.commit('setLoading', true);
        console.log('response: ', response);
        if (response.new_tab) return window.open(response.redirectUrl, '_blank');
        window.location.href = response.redirectUrl;
      }

      if (!response.success && response.message) {
        throw (response.message);
      }

      return response;
    }).catch(err => {
      console.log('Error', err);
      throw (err);
    });
}

// eslint-disable-next-line import/prefer-default-export
export const requests = {
  async getDestinations (workspace, query) {
    return makeApiRequest(`/api/centerVariations?workspace=${workspace}${query ? `&query=${query}` : ''}`, 'GET');
  },
  async getCustomers (uuids) {
    return makeApiRequest(`/api/customers?uuids=${uuids}`, 'GET');
  },
  async getCollaborator (uuid) {
    return makeApiRequest(`/api/collaborator?uuid=${uuid}`, 'GET');
  },
  async getRooms (limit, nextToken, from, filter, participantsFilter, sort) {
    const params = [];
    if (sort) {
      params.push(`sort=${JSON.stringify(sort)}`);
    }
    if (limit) {
      params.push(`limit=${limit}`);
    }
    if (nextToken) {
      params.push(`nextToken=${nextToken}`);
    }
    if (from) {
      params.push(`from=${from}`);
    }
    if (filter) {
      params.push(`filter=${JSON.stringify(filter)}`);
    }
    if (participantsFilter) {
      params.push(`participantsFilter=${JSON.stringify(participantsFilter)}`);
    }
    return makeApiRequest(`/api/amplify/rooms?${params.join('&')}`, 'GET');
  },
  async getRoomsFacets (aggregates, filter) {
    return makeApiRequest('/api/amplify/rooms/facets', 'POST', { aggregates, filter });
  },
  async getRoom (id) {
    return makeApiRequest(`/api/amplify/rooms/${id}`);
  },
  async getReports (limit, nextToken, from, filter, aggregates) {
    const params = [];
    if (limit) {
      params.push(`limit=${limit}`);
    }
    if (nextToken) {
      params.push(`nextToken=${nextToken}`);
    }
    if (from) {
      params.push(`from=${from}`);
    }
    if (filter) {
      params.push(`filter=${JSON.stringify(filter)}`);
    }
    if (aggregates) {
      params.push(`aggregates=${JSON.stringify(aggregates)}`);
    }
    return makeApiRequest(`/api/amplify/reports?${params.join('&')}`, 'GET');
  },
  async getReport (id) {
    return makeApiRequest(`/api/amplify/reports/${id}`);
  },
  async getRoomMessages (roomId, limit, nextToken) {
    const params = [];

    if (limit) {
      params.push(`limit=${limit}`);
    }
    if (nextToken) {
      params.push(`nextToken=${nextToken}`);
    }
    return makeApiRequest(`/api/amplify/rooms/${roomId}/messages?${params.join('&')}`);
  },
  async postRoom (room) {
    return makeApiRequest('/api/amplify/rooms/', 'POST', room);
  },
  async putRoom (room) {
    let response = await makeApiRequest('/api/amplify/rooms/', 'PUT', room);
    if (!response.success) {
      response = await this.putRoom({ ...room, _version: response.data._version });
    }
    return response;
  },
  async deleteRoom (room) {
    let response = await makeApiRequest('/api/amplify/rooms/', 'DELETE', room);
    if (!response.success) {
      response = await this.deleteRoom({ ...room, _version: response.data._version });
    }
    return response;
  },
  async getUser (id) {
    return makeApiRequest(`/api/amplify/users/${id}`);
  },
  async getUsers (filter) {
    return makeApiRequest(`/api/amplify/users${filter ? `?filter=${JSON.stringify(filter)}` : ''}`);
  },
  async postUser (user) {
    return makeApiRequest('/api/amplify/users/', 'POST', user);
  },
  async getParticipants (filter) {
    return makeApiRequest(`/api/amplify/participants${filter ? `?filter=${JSON.stringify(filter)}` : ''}`);
  },
  async getParticipantsFacets (aggregates, filter) {
    return makeApiRequest('/api/amplify/participants/facets', 'POST', { aggregates, filter });
  },
  async postParticipants (customersIds, roomId) {
    return makeApiRequest('/api/amplify/participants/', 'POST', { customersIds, roomId });
  },
  async postCollabParticipants (collaboratorsIds, roomId) {
    return makeApiRequest('/api/amplify/collabParticipants/', 'POST', { collaboratorsIds, roomId });
  },
  async putParticipant (participant) {
    let response = await makeApiRequest('/api/amplify/participants/', 'PUT', participant);
    if (!response.success) {
      response = await this.putParticipant({ ...participant, _version: response.data._version });
    }
    return response;
  },
  async deleteParticipant (participant) {
    let response = await makeApiRequest('/api/amplify/participants/', 'DELETE', participant);
    if (!response.success) {
      response = await this.deleteParticipant({ ...participant, _version: response.data._version });
    }
    return response;
  },
  async getRoomsParticipantsUsers (roomsAnd, participantsAnd, usersAnd, signal = null) {
    return makeApiRequest('/api/amplify/roomsParticipantsUsers/', 'POST', { roomsAnd, participantsAnd, usersAnd }, signal);
  },
  async redirectToDashboard (url, newTab = false) {
    return makeApiRequest(`/redirect?location=${url}${newTab ? '&newtab=true' : ''}`, 'GET');
  }
};

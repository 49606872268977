<template>
  <div class="d-flex flex-wrap px-4">
    <div class="breadcrumbs d-flex pa-0 col-12">
      <!-- <div>
        <v-btn
          class="mt-3 mb-4 mr-2"
          fab
          dark
          x-small
          color="primary"
          @click="changeSideBar()"
        >
          <v-icon dark>
            {{ !showFacets ? "mdi-forwardburger" : "mdi-backburger" }}
          </v-icon>
        </v-btn>
        <v-expand-x-transition>
          <div v-show="showFacets" class="sidebar">
            <Facets :key="rerender" :facets-values="facets" :facets-config="getFacetsConfiguration('groups')" :column-height="facetbarHeight - 0" />
          </div>
        </v-expand-x-transition>
      </div> -->
      <div class="content pt-4 pl-4 flex-wrap col py-0" style="height: 100%">
        <div class="d-flex justify-center items-center mb-4">
          <v-select
            v-model="searchOption"
            style="max-width: 200px"
            class="d-none d-sm-flex mr-2"
            :items="searchOptions"
            hide-details
            item-text="label"
            item-value="value"
            label="Champ de recherche"
            outlined
            dense
          />
          <SearchBar />
        </div>
        <v-card class="d-flex flex-column">
          <!--  <ais-hits :escapeHTML="false" /> -->
          <v-card-title class="py-2 d-flex  justify-end">
            <!-- <v-btn small class="" outlined @click="newGroup()">
              <v-icon>mdi-plus</v-icon>
              <span class="ml-2 d-none d-sm-inline-block">Nouveau Groupe</span>
            </v-btn> -->
            <div>
              Nombre de résultats : {{ total }}
            </div>
          </v-card-title>
          <div class="results">
            <v-divider />
            <!-- <Results
            :total="totalCount"
            :hits="hits"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort.sync="sort"
            :table-height="showFacets ? resultTableHeight : 0"
          /> -->
          </div>
          <DashboardTable
            view="groups"
            :show-list="groups"
            :page-count="pageCount"
            :total="total"
            @rowClick="groupClick"
            @rowOpenNewTab="groupClickNewTab"
            @update:page="getRooms"
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue2-helpers/vue-router';
// import Facets from '../components/Facets';
import DashboardTable from '../components/DashboardTable.vue';
import SearchBar from '../components/search/SearchBar';
import { requests } from '@/mixins/requests';
import store from '@/store';

const router = useRouter();
const route = useRoute();

const destination = computed(() => store.getters.getActiveDestination);
const context = computed(() => store.getters.getActiveContext);

const searchOption = ref('group_name');

const searchOptions = [
  {
    label: 'Nom du Groupe',
    value: 'group_name'
  },
  {
    label: 'Date de début de séjour',
    value: 'start_date'
  },
  {
    label: 'N° de dossier',
    value: 'booking_number'
  },
  {
    label: 'Nom ou Prénom du membre',
    value: 'customer_name'
  },
  {
    label: 'ID CRM du membre',
    value: 'customer_uuid'
  }
];

// const totalCount = 0;
// const hits = [];
// const facets = ref(null);
// const lastFilters = {};
// const page = 1;
// const rerender = 0;
// const itemsPerPage = 5;
// const sort = null;
// const canSearch = true; // To handle the reactive search when i don't wanna search;
// const showFacets = ref(true);
// const isDragging = false;
// const resultTableHeight = 300;
// const facetbarHeight = 285;
// const startY = 0;
// const initialSearchPerformed = false; // Flag to track the initial search

// const changeSideBar = () => showFacets.value = !showFacets.value;

// const newGroup = () => {
//   router.push({ name: 'NewGroup' });
// };

// const startDragging = event => {
//   this.startY = event.clientY;

//   this.isDragging = true;
// };

// const stopDragging = () => {
//   this.isDragging = false;
// };

// const setPreselectedFacetsByBrand = () => {
//   this.removeAllFacets();

//   if (this.brand in PreSelectedFacetsByBrand) {
//     PreSelectedFacetsByBrand[this.brand].forEach(filter => {
//       this.$store.commit('setFilter', filter);
//     });
//   }
// };

// const getFilterClauseByBrand = () => {
//   const filterClause = {
//     ucpa: [
//       { range: { available_stock: { from: 1 } } },
//       { range: { date: { from: new Date().getTime() } } }
//     ],
//     labalaguere: [
//       { range: { dateDepart: { from: new Date().getTime() } } }
//     ]
//   };
//   return this.brand in filterClause ? filterClause[this.brand] : [];
// };

// onMounted(() => {

// });

const groups = ref([]);

const pageCount = ref(1);
const total = ref(0);
// const filters = computed(() => ({ date: store.state.filters.filter(f => f.field === 'date') }));
const query = ref('');

// let filtersUnwatch = null;
let queryUnwatch = null;
let destinationUnwatch = null;
let contextUnwatch = null;
let searchOptionUnwatch = null;

const groupClick = group => {
  router.push({ name: 'GroupPage', params: { id: group.id } });
};

const groupClickNewTab = group => {
  const routeData = router.resolve({ name: 'GroupPage', params: { id: group.id } });
  window.open(routeData.href, '_blank');
};

const prepareRooms = rooms => {
  groups.value = [];
  const roomsPrep = rooms.map(r => {
    const metadata = JSON.parse(r.metadata || '{}');
    return {
      active: r.active,
      name: r.name,
      createdAt: r.createdAt.split('T')[0],
      // eslint-disable-next-line no-underscore-dangle
      participants: r.participants.items.filter(p => !p._deleted) || [],
      link: `${store.getters.getBrandUrl}/${r.id}`,
      id: r.id,
      'metadata.destination': metadata.destination || '',
      'metadata.departure_date': metadata.departure_date || '',
      'metadata.center_destination_name': metadata.center_destination_name,
      product: metadata.product_name || null,
      'metadata.product_uuid': metadata.product_uuid || null,
      'metadata.context': metadata.context,
      // eslint-disable-next-line no-underscore-dangle
      _version: r._version
    };
  });

  groups.value.push(...roomsPrep);
};

// const facetsUpdate = newFacets => {
//   if (!filters.value.date.length) facets.value.date.data = [];

//   console.log('newFacets :', newFacets);
//   newFacets.forEach(i => {
//     if (!filters.value.date.length) {
//       if (i.name === 'minDate' || i.name === 'maxDate') {
//         if (i.result) {
//           facets.value.date.data.push({ value: i.result.value });
//         } else {
//           facets.value.date.data = [];
//         }
//       }
//     }
//   });
// };

const getParticipantsRoomIds = async () => {
  const participantsAnd = [];
  const usersAnd = [];
  switch (searchOption.value) {
    case 'booking_number': {
      participantsAnd.push({ field: 'metadata.bookingNumber', condition: { matchPhrase: `${query.value}` } });
      break;
    }
    case 'customer_name':
      usersAnd.push({
        or: [
          { field: 'firstname', condition: { matchPhrase: `${query.value}` } },
          { field: 'lastname', condition: { matchPhrase: `${query.value}` } }
        ]
      });
      break;
    case 'customer_uuid':
      usersAnd.push({ field: 'metadata.trackId', condition: { eq: `${query.value}` } });
      break;
    default:
      break;
  }

  if (usersAnd.length) {
    // make users request
    const usersResponse = await requests.getUsers({ and: usersAnd }).then(
      r => r
    ).catch(err => err);

    if (usersResponse.success) {
      participantsAnd.push({ or: (usersResponse.data?.items || []).map(user => ({ field: 'userId', condition: { eq: user.id } })) });
    }
  }

  // make participants request
  const participantsResponse = await requests.getParticipants({ and: participantsAnd }).then(
    r => r
  ).catch(err => err);

  if (participantsResponse.success) {
    const groupIds = new Set();
    (participantsResponse.data?.items || []).forEach(participant => {
      groupIds.add(participant.roomId);
    });

    return Array.from(groupIds);
  }

  return [];
};

const getRooms = async options => {
  const { sortBy, sortDesc, page/* , itemsPerPage */ } = options;

  store.state.loading = true;
  const limit = 10;
  const platforms = store.state.userPlatforms;
  const and = [{ field: 'metadata.workspace', condition: { eq: store.state.activeBrand } }];

  if (!platforms.includes('*')) {
    and.push({ or: platforms.map(permission => ({ field: 'metadata.platform', condition: { eq: permission } })) });
  }

  if (destination.value) {
    and.push({ field: 'metadata.center_destination_name', condition: { eq: destination.value } });
  }

  if (context.value) {
    and.push({ field: 'metadata.context', condition: { eq: context.value } });
  }

  // if (filters.value.date.length) {
  //   and.push({ createdAt: { range: [filters.value.date[0].value, filters.value.date[0].value] } });
  // }

  if ((query.value)) {
    switch (searchOption.value) {
      case 'booking_number':
      case 'customer_name':
      case 'customer_uuid': {
        const participantsRoomIds = await getParticipantsRoomIds();
        and.push({ or: participantsRoomIds.map(id => ({ field: 'id', condition: { eq: id } })) });
        break;
      }
      case 'start_date':
        and.push({ field: 'metadata.departure_date', condition: { matchPhrase: `${query.value}` } });
        break;
      case 'group_name':
      default:
        and.push({ field: 'name', condition: { matchPhrase: `${query.value}` } });
        break;
    }
  }

  const filter = and.length ? { and: and } : null;
  const sort = sortBy && sortBy.length ? sortBy.map((item, index) => ({ direction: sortDesc[index] ? 'desc' : 'asc', field: item === 'product' ? 'metadata.product_name' : item })) : null;

  const response = await requests.getRooms(limit, null, limit * (page - 1), filter, null, sort).then(
    r => r
  ).catch(err => {
    store.state.loading = false;
    return err;
  });

  if (response.success) {
    // facetsUpdate(response.data.aggregateItems);
    total.value = response.data.total;
    pageCount.value = Math.ceil(total.value / limit);

    prepareRooms(response.data.items);
  } else {
    prepareRooms([]);
  }

  store.state.loading = false;
};

onMounted(async () => {
  await getRooms({ page: 1 });

  // filtersUnwatch = watch(filters, async () => {
  //   await getRooms(1);
  // }, { deep: true });
  queryUnwatch = watch(() => route.query.query, async newVal => {
    query.value = newVal || '';
    await getRooms({ page: 1 });
  });
  searchOptionUnwatch = watch(() => searchOption.value, async () => {
    await getRooms({ page: 1 });
  });
  destinationUnwatch = watch(() => destination.value, async () => {
    await getRooms({ page: 1 });
  });
  contextUnwatch = watch(() => context.value, async () => {
    await getRooms({ page: 1 });
  });
});

onUnmounted(() => {
  console.log('Unmounted');
  // if (filtersUnwatch) {
  //   filtersUnwatch();
  // }
  if (queryUnwatch) {
    queryUnwatch();
  }
  if (destinationUnwatch) {
    destinationUnwatch();
  }
  if (contextUnwatch) {
    contextUnwatch();
  }
  if (searchOptionUnwatch) {
    searchOptionUnwatch();
  }
});

</script>

<style lang="scss">
.breadcrumbs {
  min-height: 40px;
}
.sidebar {
  width: 330px;
  height: calc(100vh - 185px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.content {
  width: calc(100% - 330px);
  height: calc(100vh - 140px);
}
.results {
  max-height: calc(100vh - 275px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>

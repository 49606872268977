import VueRouter from 'vue-router';

// Used Components
import Home from '@/views/Home';
import NewGroup from '@/views/NewGroup';
import GroupPage from '@/views/GroupPage';
import MasterLayout from '@/views/template/MasterLayout.vue';
// import ReportsDashboard from '@/views/ReportsDashboard';
// import ReportInfo from '@/views/ReportInfo';
// import Targeting from '@/views/Targeting';

import { isAuthenticated } from '@/utils/auth';
import store from '@/store';

const { VUE_APP_SSO_PUBLIC_JWK_SET } = process.env;

const routes = [
  {
    path: '/',
    component: MasterLayout,
    children: [
      { path: '/', name: 'Home', component: Home },
      { path: '/groupe/new', name: 'NewGroup', component: NewGroup },
      { path: 'groupe/:id', name: 'GroupPage', component: GroupPage, props: true }
      // { path: 'signale', name: 'ReportsDashboard', component: ReportsDashboard },
      // { path: 'signale/:id', name: 'ReportInfo', component: ReportInfo, props: true },
      // { path: 'ciblage', name: 'Targeting', component: Targeting, props: true }
    ]
  },
  { path: '/home', name: 'home', component: Home, meta: { componentName: 'Home' } }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  const authResponse = await isAuthenticated(store.state.token, VUE_APP_SSO_PUBLIC_JWK_SET);

  if (!authResponse || !authResponse.writeWorkspaces) {
    window.location.href = `${window.location.origin}/logout`;
    return next(false);
  }

  store.commit('setReadWorkspaces', authResponse.readWorkspaces);
  store.commit('setUserPlatforms', authResponse.writeWorkspaces);
  store.commit('setUserRoles', authResponse.roles);

  return next();
});

export default router;
